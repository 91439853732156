import React, { useState, useCallback, useMemo, useEffect, forwardRef, useImperativeHandle } from 'react';
import QueryInput from './QueryInput';
import ResultView from './ResultView';
import ControlPanel from './ControlPanel';
import ActionPanel from './ActionPanel';
import Card from '../Card';
import AlignedButtonLayout from './AlignedButtonLayout';
import { useAuth } from '../../contexts/AuthContext';
import Modal from '../Modal';
import * as XLSX from 'xlsx';
import { X } from 'lucide-react';

const QueryComponent = forwardRef(({ onQueryResult, onQueryStart, queryResult, isLoading, onSaveQuery }, ref) => {
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const [viewMode, setViewMode] = useState('table');
  const [chartType, setChartType] = useState('bar');
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', message: '', isInput: false });
  const [queryName, setQueryName] = useState('');
  const [isShared, setIsShared] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveCompleted, setSaveCompleted] = useState(false);
  const [isExplanationLoading, setIsExplanationLoading] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [showResultCard, setShowResultCard] = useState(false);
  const { currentUser } = useAuth();

  const resultRef = React.useRef(null);

  useImperativeHandle(ref, () => ({
    scrollToResult: () => {
      if (resultRef.current) {
        resultRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }));

  const handleSubmit = useCallback(async (submittedQuery) => {
    onQueryStart();
    setError('');
    setShowResultCard(true);
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 120000);

      const userId = currentUser?.email || 'anonymous';

      const response = await fetch('https://us-central1-matchup-9ebf8.cloudfunctions.net/processDynamicQueryWithCORS', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            userQuery: submittedQuery,
            userId: userId
          }, 
          context: { auth: true } 
        }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Network response was not ok');
      }
      
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      const newResult = {
        ...data,
        userQuery: submittedQuery,
        isSavedQuery: false,
        config: {
          usedModel: data.usedModel,
          fallbackUsed: data.fallbackUsed,
        },
        retryCount: data.retryCount,
        retryResult: data.retryResult,
        bigQueryError: data.bigQueryError,
      };
      onQueryResult(newResult);
      setTimeout(() => {
        if (resultRef.current) {
          resultRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } catch (err) {
      if (err.name === 'AbortError') {
        setError('The request took too long to respond. Please try again or simplify your query.');
      } else {
        setError(err.message || 'An error occurred while processing your query. Please try again.');
      }
      onQueryResult(null);
    }
  }, [onQueryResult, onQueryStart, currentUser]);

  const handleSaveQuery = async () => {
    if (!queryResult) return;
    setModalContent({
      title: 'Save Query',
      message: 'Enter a name for this query:',
      isInput: true
    });
    setIsModalOpen(true);
    setSaveCompleted(false);
  };

  const handleModalSubmit = async () => {
    if (!queryName || saveCompleted) {
      handleModalClose();
      return;
    }
    
    setIsSaving(true);
    try {
      const response = await fetch('https://us-central1-matchup-9ebf8.cloudfunctions.net/saveQuery', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            userId: currentUser.email,
            name: queryName,
            userQuery: queryResult.userQuery,
            sqlQuery: queryResult.sqlQuery,
            llmModel: queryResult.usedModel,
            fallbackUsed: queryResult.fallbackUsed,
            originalModel: queryResult.originalModel,
            documentId: queryResult.documentId,
            isPrivate: !isShared,
            explanation: explanation
          }
        })
      });
      const { id } = await response.json();
      setModalContent({
        title: 'Success',
        message: `Query saved with ID: ${id}`,
        isInput: false
      });
      setSaveCompleted(true);
      if (onSaveQuery) {
        onSaveQuery();
      }
      setTimeout(() => {
        handleModalClose();
      }, 2000);
    } catch (error) {
      console.error('Error saving query:', error);
      setModalContent({
        title: 'Error',
        message: 'Failed to save query. Please try again.',
        isInput: false
      });
      setTimeout(() => {
        handleModalClose();
      }, 3000);
    } finally {
      setIsSaving(false);
    }
  };
  
  const handleModalClose = () => {
    setIsModalOpen(false);
    setQueryName('');
    setIsShared(false);
    setIsSaving(false);
    setSaveCompleted(false);
    setModalContent({
      title: '',
      message: '',
      isInput: true
    });
  };

  const handleExplanationClick = async () => {
    if (explanation) return;
    setIsExplanationLoading(true);
    try {
      const requestData = {
        sqlQuery: queryResult.sqlQuery,
        documentId: queryResult.documentId,
        savedQueryId: queryResult.savedQueryId
      };
      console.log('Sending data for explanation:', requestData);
      const response = await fetch('https://us-central1-matchup-9ebf8.cloudfunctions.net/generateExplanation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: requestData })
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Network response was not ok: ${errorData.error}, ${errorData.details}`);
      }
      const data = await response.json();
      setExplanation(data.explanation);
      onQueryResult({
        ...queryResult,
        explanation: data.explanation
      });
    } catch (error) {
      console.error('Error generating explanation:', error);
      setError(`Failed to generate explanation: ${error.message}`);
    } finally {
      setIsExplanationLoading(false);
    }
  };

  const resultDimensions = useMemo(() => {
    if (!queryResult || !queryResult.result || queryResult.result.length === 0) return 0;
    return Object.keys(queryResult.result[0]).length;
  }, [queryResult]);

  const isSingleValue = useMemo(() => {
    return queryResult && queryResult.result.length === 1 && resultDimensions === 1;
  }, [queryResult, resultDimensions]);

  const showControls = useMemo(() => {
    return queryResult && queryResult.result && queryResult.result.length > 1 && resultDimensions >= 2;
  }, [queryResult, resultDimensions]);

  const canShowGraph = useMemo(() => {
    if (!queryResult || !queryResult.result || queryResult.result.length === 0) return false;
    const result = queryResult.result;
    if (result.length === 1 && Object.keys(result[0]).length <= 3) return false;
    return result.length > 1 && Object.keys(result[0]).length >= 2;
  }, [queryResult]);

  const toggleViewMode = useCallback(() => {
    if (canShowGraph) {
      setViewMode(prevMode => prevMode === 'chart' ? 'table' : 'chart');
    }
  }, [canShowGraph]);

  const handleExcelDownload = useCallback(() => {
    if (queryResult && queryResult.result) {
      const worksheet = XLSX.utils.json_to_sheet(queryResult.result);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, "query_result.xlsx");
    }
  }, [queryResult]);

  useEffect(() => {
    if (queryResult && queryResult.result) {
      setShowResultCard(true);
      let keys;
      if (Array.isArray(queryResult.result)) {
        keys = Object.keys(queryResult.result[0]);
      } else {
        keys = ['key', 'value'];
      }
      
      const dateColumn = keys.find(key => 
        typeof queryResult.result[0][key] === 'string' && 
        queryResult.result[0][key].match(/^\d{4}-\d{2}-\d{2}/)
      ) || keys[0];
      
      const valueColumn = keys.find(key => typeof queryResult.result[0][key] === 'number') || keys[1];
      
      setSelectedXAxis(dateColumn);
      setSelectedYAxis(valueColumn);
      setSortColumn(dateColumn);
      setSortOrder('asc');
      setExplanation(queryResult.explanation || '');

      if (keys.length === 2 && keys.includes('key') && keys.includes('value')) {
        setChartType('pie');
      } else {
        setChartType('bar');
      }

      setViewMode('table');
    }
  }, [queryResult]);

  const handleCloseResultCard = () => {
    setShowResultCard(false);
  };

  return (
    <div>
      <QueryInput onSubmit={handleSubmit} isLoading={isLoading} />
      {error && (
        <Card className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <p>{error}</p>
        </Card>
      )}
      {queryResult && showResultCard && (
        <Card 
          ref={resultRef}
          title={queryResult.name || queryResult.formattedTitle || 'Result'}
          titleSize="text-card-title-lg" 
          contentSize="text-base"
          className="overflow-hidden relative"
        >
          <button
            onClick={handleCloseResultCard}
            className="absolute top-2 right-2 p-1 rounded-full hover:bg-gray-200 transition-colors duration-200"
            aria-label="Close result"
          >
            <X size={18} />
          </button>
          <div className="flex flex-col h-full">
            {queryResult.retryCount > 0 && queryResult.retryResult !== 'Success' && (
              <div className="mb-4 p-2 rounded bg-yellow-100 text-yellow-700">
                Query required {queryResult.retryCount} retry but still failed.
                {queryResult.bigQueryError && (
                  <p className="mt-2 text-sm">Error: {queryResult.bigQueryError}</p>
                )}
              </div>
            )}
            <AlignedButtonLayout
              viewMode={viewMode}
              toggleViewMode={toggleViewMode}
              showControls={showControls}
              chartType={chartType}
              setChartType={setChartType}
              downloadExcel={handleExcelDownload}
              canShowGraph={canShowGraph}
            />
            {showControls && viewMode === 'chart' && (
              <ControlPanel
                chartType={chartType}
                setChartType={setChartType}
                selectedXAxis={selectedXAxis}
                setSelectedXAxis={setSelectedXAxis}
                selectedYAxis={selectedYAxis}
                setSelectedYAxis={setSelectedYAxis}
                sortColumn={sortColumn}
                setSortColumn={setSortColumn}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                columns={queryResult.result[0] ? Object.keys(queryResult.result[0]) : []}
              />
            )}
            <div className="flex-grow overflow-x-auto mt-4">
              <ResultView 
                result={queryResult}
                viewMode={canShowGraph ? viewMode : 'table'}
                chartType={chartType}
                selectedXAxis={selectedXAxis}
                selectedYAxis={selectedYAxis}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                isSingleValue={isSingleValue}
              />
            </div>
            <div className="mt-auto">
              <ActionPanel 
                result={queryResult} 
                onSaveQuery={handleSaveQuery} 
                isSavedQuery={queryResult.isSavedQuery}
                onExplanationClick={handleExplanationClick}
                isExplanationLoading={isExplanationLoading}
                explanation={explanation}
              />            
            </div>
          </div>
        </Card>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={saveCompleted ? handleModalClose : handleModalSubmit}
        title={modalContent.title}
        message={modalContent.message}
        isInput={modalContent.isInput && !saveCompleted}
        value={queryName}
        onChange={(e) => setQueryName(e.target.value)}
        isLoading={isSaving}
        isShared={isShared}
        onShareToggle={() => setIsShared(!isShared)}
        showButtons={!saveCompleted}
      />
    </div>
  );
});

export default QueryComponent;