import React from 'react';
import Button from '../Button';
import ChartToggle from './ChartToggle';
import { Table, BarChart, Download } from 'lucide-react';

const AlignedButtonLayout = ({
  viewMode,
  toggleViewMode,
  showControls,
  chartType,
  setChartType,
  downloadExcel,
  canShowGraph
}) => {
  return (
    <div className="flex flex-wrap items-center gap-2">
      {canShowGraph && (
        <Button
          onClick={toggleViewMode}
          icon={viewMode === 'chart' ? Table : BarChart}
          className="text-sm h-9 px-3 py-2"
        >
          {viewMode === 'chart' ? 'Table' : 'Graph'}
        </Button>
      )}
      <Button
        onClick={downloadExcel}
        icon={Download}
        className="text-sm h-9 px-3 py-2"
      >
        Excel
      </Button>
      {showControls && viewMode === 'chart' && canShowGraph && (
        <ChartToggle chartType={chartType} onChange={setChartType} />
      )}
    </div>
  );
};

export default AlignedButtonLayout;