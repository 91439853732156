import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Button from '../Button';
import ChartToggle from './ChartToggle';

const ControlPanel = ({ 
  chartType, setChartType, 
  selectedXAxis, setSelectedXAxis, selectedYAxis, setSelectedYAxis,
  sortColumn, setSortColumn, sortOrder, setSortOrder, columns
}) => {
  const [showAdvancedControls, setShowAdvancedControls] = useState(false);

  if (columns.length < 2) {
    return null;
  }

  return (
    <div className="flex flex-col w-full mt-2">
      <div className="flex items-center gap-2">
        <Button
          onClick={() => setShowAdvancedControls(!showAdvancedControls)}
          className="text-sm h-9 px-3 py-2"
          icon={showAdvancedControls ? ChevronUp : ChevronDown}
        >
          Adjust
        </Button>
      </div>
      {showAdvancedControls && (
        <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
          <select
            value={selectedXAxis}
            onChange={(e) => setSelectedXAxis(e.target.value)}
            className="form-select text-sm py-1 px-2 border rounded-md bg-white"
          >
            <option value="" disabled>X-Axis</option>
            {columns.map(column => (
              <option key={column} value={column}>{column}</option>
            ))}
          </select>
          <select
            value={selectedYAxis}
            onChange={(e) => setSelectedYAxis(e.target.value)}
            className="form-select text-sm py-1 px-2 border rounded-md bg-white"
          >
            <option value="" disabled>Y-Axis</option>
            {columns.map(column => (
              <option key={column} value={column}>{column}</option>
            ))}
          </select>
          <select
            value={sortColumn}
            onChange={(e) => setSortColumn(e.target.value)}
            className="form-select text-sm py-1 px-2 border rounded-md bg-white"
          >
            <option value="">Sort by</option>
            {columns.map(column => (
              <option key={column} value={column}>{column}</option>
            ))}
          </select>
          <select
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            className="form-select text-sm py-1 px-2 border rounded-md bg-white"
          >
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </select>
        </div>
      )}
    </div>
  );
};

export default ControlPanel;