import React from 'react';

const Card = ({ children, title, className = '', titleSize = 'text-lg', contentSize = 'text-sm' }) => {
  const defaultClasses = 'bg-white shadow-card rounded-lg p-4 mb-4 flex flex-col';
  const combinedClasses = `${defaultClasses} ${className}`.trim();

  return (
    <div className={combinedClasses}>
      {title && <h2 className={`${titleSize} font-semibold mb-4 text-gray-700`}>{title}</h2>}
      <div className={`${contentSize} flex-grow flex flex-col`}>
        {children}
      </div>
    </div>
  );
};

export default Card;