import React, { useState } from 'react';
import { Copy, Code, FileText, Save } from 'lucide-react';
import Button from '../Button';
import PopupCard from '../PopupCard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const ActionPanel = ({ result, onSaveQuery, isSavedQuery, onExplanationClick, isExplanationLoading, explanation }) => {
  const [showSqlPopup, setShowSqlPopup] = useState(false);
  const [showLogsPopup, setShowLogsPopup] = useState(false);
  const [copiedResult, setCopiedResult] = useState(false);
  const [copiedSql, setCopiedSql] = useState(false);

  const copyToClipboard = (text, setCopiedState) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedState(true);
      setTimeout(() => setCopiedState(false), 2000);
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const formatLogs = () => {
    return `
User Query:
${result.userQuery}

Configuration Details:
${JSON.stringify(result.config, null, 2)}

SQL Generated by LLM:
${result.sqlQuery}

Response from BigQuery:
${JSON.stringify(result.result, null, 2)}

Document ID:
${result.documentId}
    `;
  };

  return (
    <div className="mt-4 flex justify-end space-x-2">
      <Button
        onClick={() => copyToClipboard(JSON.stringify(result.result, null, 2), setCopiedResult)}
        icon={Copy}
        className="p-2"
        title={copiedResult ? 'Copied!' : 'Copy'}
      >
        {copiedResult ? 'Copied!' : ''}
      </Button>
      <Button
        onClick={() => setShowSqlPopup(true)}
        icon={Code}
        className="p-2"
        title="SQL"
      />
      <Button
        onClick={() => setShowLogsPopup(true)}
        icon={FileText}
        className="p-2"
        title="Logs"
      />
      {!isSavedQuery && (
        <Button
          onClick={onSaveQuery}
          icon={Save}
          className="p-2"
          title="Save Query"
        />
      )}

      <PopupCard
        isOpen={showSqlPopup}
        onClose={() => setShowSqlPopup(false)}
        title="SQL Query"
        footer={
          <Button
            onClick={() => copyToClipboard(result.sqlQuery, setCopiedSql)}
            icon={Copy}
            className="p-2"
            title={copiedSql ? 'Copied!' : 'Copy'}
          >
            {copiedSql ? 'Copied!' : ''}
          </Button>
        }
        className="z-50"
        contentClassName="w-full max-w-2xl"
        onExplanationClick={onExplanationClick}
        isExplanationLoading={isExplanationLoading}
        explanation={explanation}
        showExplanationButton={true}
      >
        <div className="max-h-[60vh] overflow-auto">
          <SyntaxHighlighter language="sql" style={docco} customStyle={{fontSize: '0.875rem'}}>
            {result?.sqlQuery}
          </SyntaxHighlighter>
        </div>
      </PopupCard>

      <PopupCard
        isOpen={showLogsPopup}
        onClose={() => setShowLogsPopup(false)}
        title="Debug Logs"
        className="z-50"
        contentClassName="w-full max-w-2xl"
        showExplanationButton={false}
      >
        <div className="mt-4 p-4 bg-gray-50 rounded-md max-h-[60vh] overflow-y-auto">
          <pre className="whitespace-pre-wrap break-words text-sm">
            {formatLogs()}
          </pre>
        </div>
      </PopupCard>
    </div>
  );
};

export default ActionPanel;