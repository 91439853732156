import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './components/Layout';
import Routes from './Routes';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import './assets/bubblingLoader.css';

function App() {
  useEffect(() => {
    const currentBuildTime = localStorage.getItem('buildTime');
    const newBuildTime = process.env.REACT_APP_BUILD_TIME;

    if (currentBuildTime !== newBuildTime) {
      localStorage.setItem('buildTime', newBuildTime);
      if (currentBuildTime) {
        window.location.reload(true);
      }
    }
  }, []);

  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <Layout>
            <Routes />
          </Layout>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;