import React, { useEffect, useState } from 'react';
import { BarChart, LineChart, PieChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, Line, Pie, Cell, ResponsiveContainer } from 'recharts';
import { format, parseISO } from 'date-fns';

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#0088FE', '#00C49F'];

const ChartView = ({ data, chartType, selectedXAxis, selectedYAxis }) => {
  const [localSelectedXAxis, setLocalSelectedXAxis] = useState(selectedXAxis);
  const [localSelectedYAxis, setLocalSelectedYAxis] = useState(selectedYAxis);

  useEffect(() => {
    let xAxis = selectedXAxis;
    let yAxis = selectedYAxis;

    if (xAxis === yAxis && data.length > 0) {
      const availableKeys = Object.keys(data[0] || {});
      const alternativeAxis = availableKeys.find(key => key !== xAxis && typeof data[0][key] === 'number');
      
      if (alternativeAxis) {
        yAxis = alternativeAxis;
      } else {
        yAxis = availableKeys.find(key => key !== xAxis) || yAxis;
      }
    }

    setLocalSelectedXAxis(xAxis);
    setLocalSelectedYAxis(yAxis);
  }, [data, selectedXAxis, selectedYAxis]);

  const formatXAxis = (tickItem) => {
    if (typeof tickItem === 'string' && tickItem.match(/^\d{4}-\d{2}-\d{2}/)) {
      return format(parseISO(tickItem), 'MMM d');
    }
    return tickItem;
  };

  const formatYAxis = (value) => {
    if (typeof value === 'number') {
      return isPercentage ? `${value.toFixed(2)}%` : value.toFixed(2);
    }
    return value;
  };

  const formatTooltip = (value, name, props) => {
    if (typeof value === 'number') {
      return isPercentage ? `${value.toFixed(2)}%` : value.toFixed(2);
    }
    return value;
  };

  const axisStyle = {
    fontSize: '12px',
    fontFamily: 'inherit',
  };

  const isPercentage = localSelectedYAxis.toLowerCase().includes('percent');

  const validData = data.filter(item => {
    const yValue = item[localSelectedYAxis];
    return yValue !== null && yValue !== undefined && !isNaN(yValue);
  });

  const getYDomain = () => {
    if (validData.length === 0) return [0, 1];

    const values = validData.map(item => parseFloat(item[localSelectedYAxis]));
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    
    if (minValue === maxValue) {
      return [minValue * 0.9, minValue * 1.1];
    }

    const padding = (maxValue - minValue) * 0.1;
    return [Math.max(0, minValue - padding), maxValue + padding];
  };

  if (validData.length === 0) {
    return <div>No valid data to display in the chart.</div>;
  }

  const renderChart = () => {
    switch (chartType) {
      case 'bar':
        return (
          <BarChart data={validData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey={localSelectedXAxis} 
              tickFormatter={formatXAxis}
              angle={-45}
              textAnchor="end"
              height={60}
              tick={axisStyle}
            />
            <YAxis 
              tickFormatter={formatYAxis}
              tick={axisStyle}
              domain={getYDomain()}
            />
            <Tooltip 
              labelFormatter={formatXAxis} 
              formatter={formatTooltip}
            />
            <Legend wrapperStyle={{ paddingTop: '5px' }} />
            <Bar dataKey={localSelectedYAxis} fill="#8884d8" />
          </BarChart>
        );
      case 'line':
        return (
          <LineChart data={validData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey={localSelectedXAxis} 
              tickFormatter={formatXAxis}
              angle={-45}
              textAnchor="end"
              height={60}
              tick={axisStyle}
            />
            <YAxis 
              tickFormatter={formatYAxis}
              tick={axisStyle}
              domain={getYDomain()}
            />
            <Tooltip 
              labelFormatter={formatXAxis} 
              formatter={formatTooltip}
            />
            <Legend wrapperStyle={{ paddingTop: '5px' }} />
            <Line type="monotone" dataKey={localSelectedYAxis} stroke="#8884d8" />
          </LineChart>
        );
      case 'pie':
        return (
          <PieChart margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
            <Pie
              data={validData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey={localSelectedYAxis}
              nameKey={localSelectedXAxis}
              label={({name, percent}) => `${name}: ${(percent * 100).toFixed(0)}%`}
            >
              {validData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={formatTooltip} />
            <Legend wrapperStyle={{ paddingTop: '5px' }} />
          </PieChart>
        );
      default:
        return null;
    }
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      {renderChart()}
    </ResponsiveContainer>
  );
};

export default ChartView;