import React, { useMemo } from 'react';
import ChartView from './ChartView';
import TableView from './TableView';
import { format, parseISO } from 'date-fns';

const ResultView = ({ result, viewMode, chartType, selectedXAxis, selectedYAxis, sortColumn, sortOrder, isSingleValue }) => {
  const formatDate = (dateString) => {
    try {
      return format(parseISO(dateString), 'yyyy-MM-dd');
    } catch {
      return dateString;
    }
  };

  const formatValue = (value, key) => {
    if (value === null || value === undefined) return 'N/A';
    if (typeof value === 'number') {
      if (key.toLowerCase().includes('percent') || key.toLowerCase().includes('percentage')) {
        return value.toFixed(2) + '%';
      }
      if (value % 1 !== 0) {
        return value.toFixed(2);
      }
      return value.toString();
    }
    if (typeof value === 'string') {
      if (value.endsWith('%')) {
        const numericValue = parseFloat(value);
        if (!isNaN(numericValue)) {
          return numericValue.toFixed(2) + '%';
        }
      }
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        if (numericValue % 1 !== 0) {
          return numericValue.toFixed(2);
        }
        return numericValue.toString();
      }
    }
    return value;
  };

  const formatColumnName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const processedData = useMemo(() => {
    if (!result || !result.result) return [];

    if (Array.isArray(result.result)) {
      return result.result.map(item => {
        const processedItem = {};
        Object.entries(item || {}).forEach(([key, value]) => {
          const formattedKey = formatColumnName(key);
          if (value !== null && typeof value === 'object' && 'value' in value) {
            value = value.value;
          }
          if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}/)) {
            processedItem[formattedKey] = formatDate(value);
          } else {
            const numericValue = parseFloat(value);
            processedItem[formattedKey] = isNaN(numericValue) ? value : numericValue;
          }
        });
        return processedItem;
      });
    } else if (typeof result.result === 'object') {
      const processedData = [];
      for (const key in result.result) {
        if (result.result.hasOwnProperty(key)) {
          const value = result.result[key];
          const numericValue = parseFloat(value);
          processedData.push({
            [formatColumnName(selectedXAxis)]: formatColumnName(key),
            [formatColumnName(selectedYAxis)]: isNaN(numericValue) ? value : numericValue
          });
        }
      }
      return processedData;
    }
    return [];
  }, [result, selectedXAxis, selectedYAxis]);

  const sortData = (data) => {
    if (!sortColumn) return data;
    const formattedSortColumn = formatColumnName(sortColumn);
    return [...data].sort((a, b) => {
      const aVal = a[formattedSortColumn];
      const bVal = b[formattedSortColumn];
      if (aVal < bVal) return sortOrder === 'asc' ? -1 : 1;
      if (aVal > bVal) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const sortedData = sortData(processedData);
  const hasMultipleRows = sortedData.length > 1;

  if (isSingleValue && sortedData.length > 0) {
    const key = Object.keys(sortedData[0])[0];
    const value = sortedData[0][key];
    const formattedValue = formatValue(value, key);
    return (
      <div className="text-left">
        <p className="text-lg font-semibold">{formatColumnName(key)}: <span className="text-xl font-bold">{formattedValue}</span></p>
      </div>
    );
  }

  if (!hasMultipleRows || (sortedData.length > 0 && Object.keys(sortedData[0]).length === 1)) {
    return (
      <div className="relative">
        <TableView data={sortedData} />
      </div>
    );
  }

  return viewMode === 'chart' ? (
    <ChartView 
      data={sortedData}
      chartType={chartType}
      selectedXAxis={formatColumnName(selectedXAxis)}
      selectedYAxis={formatColumnName(selectedYAxis)}
    />
  ) : (
    <div className="relative">
      <TableView data={sortedData} />
    </div>
  );
};

export default ResultView;