import React, { useState } from 'react';
import { Book } from 'lucide-react';
import Button from './Button';

const ExplanationButton = ({ onClick, isLoading }) => {
  return (
    <Button
      onClick={onClick}
      icon={Book}
      className="p-2 mb-4"
      title="Translate Logic"
      disabled={isLoading}
    >
      {isLoading ? 'Translating...' : 'Translate Logic'}
    </Button>
  );
};

export default ExplanationButton;
