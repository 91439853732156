import React, { useState, useRef, useEffect } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';

const TableView = ({ data }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [tableHeight, setTableHeight] = useState('auto');
  const headerRef = useRef(null);

  useEffect(() => {
    if (headerRef.current && data.length > 7) {
      const headerHeight = headerRef.current.offsetHeight;
      const rowHeight = 40; // Approximate height of a row
      setTableHeight(`${headerHeight + (rowHeight * 7)}px`);
    } else {
      setTableHeight('auto');
    }
  }, [data]);

  if (!data || data.length === 0) return null;

  const formatValue = (value) => {
    if (typeof value === 'number') {
      return value.toFixed(2);
    }
    if (typeof value === 'string' && value.endsWith('%')) {
      return parseFloat(value).toFixed(2) + '%';
    }
    return value;
  };

  const sortData = (data, sortConfig) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'asc' ? 
        <ChevronUp className="inline-block w-4 h-4 ml-1" /> :
        <ChevronDown className="inline-block w-4 h-4 ml-1" />;
    }
    return null;
  };

  const isSimpleTable = data.length === 1;
  const sortedData = sortData(data, sortConfig);

  const renderTable = () => {
    if (isSimpleTable) {
      return (
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2 bg-gray-100 text-sm font-semibold text-left">Metric</th>
              <th className="border border-gray-300 p-2 bg-gray-100 text-sm font-semibold text-left">Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(sortedData[0]).map(([key, value], index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                <td className="border border-gray-300 p-2 text-sm font-medium text-left">{key}</td>
                <td className="border border-gray-300 p-2 text-sm text-right">{formatValue(value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    return (
      <div className="overflow-x-auto" style={{ height: tableHeight }}>
        <table className="w-full border-collapse border border-gray-300">
          <thead ref={headerRef} className="sticky top-0 z-10 bg-gray-100">
            <tr>
              {Object.keys(sortedData[0]).map(key => (
                <th 
                  key={key} 
                  className="border border-gray-300 p-2 text-sm font-semibold text-left cursor-pointer hover:bg-gray-200 transition-colors"
                  onClick={() => requestSort(key)}
                >
                  {key}
                  {getSortIcon(key)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="overflow-y-auto">
            {sortedData.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                {Object.entries(row).map(([key, value], i) => (
                  <td key={i} className="border border-gray-300 p-2 text-sm text-left">
                    {formatValue(value)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      {renderTable()}
    </div>
  );
};

export default TableView;