import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Card from '../Card';
import Button from '../Button';
import { Play, Trash2 } from 'lucide-react';
import '../../assets/bubblingLoader.css';
import { useAuth } from '../../contexts/AuthContext';
import Modal from '../Modal';

const SavedQueriesComponent = forwardRef(({ onQueryResult, onQueryStart }, ref) => {
  const [allQueries, setAllQueries] = useState([]);
  const [filteredQueries, setFilteredQueries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [queryToDelete, setQueryToDelete] = useState(null);
  const [isDeletingQuery, setIsDeletingQuery] = useState(false);
  const [filter, setFilter] = useState('all');
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchSavedQueries = async () => {
    setIsLoading(true);
    try {
      const queriesQuery = query(
        collection(db, 'SavedQueries'),
        where('isDeleted', 'in', [false, null])
      );

      const queriesSnapshot = await getDocs(queriesQuery);
      const queriesData = queriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const sortedQueries = queriesData.sort((a, b) => (b.counter || 0) - (a.counter || 0));

      setAllQueries(sortedQueries);
      setFilteredQueries(sortedQueries);
      setError(null);
    } catch (err) {
      console.error('Error fetching saved queries:', err);
      setError('Failed to load saved queries. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedQueries();
  }, [currentUser.email]);

  useEffect(() => {
    if (filter === 'all') {
      setFilteredQueries(allQueries.filter(q => !q.isPrivate || q.userId === currentUser.email));
    } else if (filter === 'my') {
      setFilteredQueries(allQueries.filter(q => q.userId === currentUser.email));
    } else if (filter === 'shared') {
      setFilteredQueries(allQueries.filter(q => !q.isPrivate && q.userId !== currentUser.email));
    }
  }, [filter, allQueries, currentUser.email]);

  useImperativeHandle(ref, () => ({
    refreshQueries: fetchSavedQueries
  }));

  const handleRunSavedQuery = async (query) => {
    onQueryStart();
    setErrorMessage(null);
    try {
      const response = await fetch('https://us-central1-matchup-9ebf8.cloudfunctions.net/runSavedQuery', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: { savedQueryId: query.id }, context: { auth: true } })
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.details || 'Network response was not ok');
      }
      const data = await response.json();
      onQueryResult({
        ...data,
        userQuery: query.userQuery,
        name: query.name,
        isSavedQuery: true,
        config: {
          usedModel: data.usedModel,
          fallbackUsed: data.fallbackUsed,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error running saved query:', error);
      setErrorMessage(error.message);
      onQueryResult(null);
    }
  };

  const handleDeleteQuery = (query) => {
    setQueryToDelete(query);
    setIsModalOpen(true);
  };

  const confirmDeleteQuery = async () => {
    if (!queryToDelete) return;
    setIsDeletingQuery(true);
    try {
      const response = await fetch('https://us-central1-matchup-9ebf8.cloudfunctions.net/deleteSavedQuery', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            savedQueryId: queryToDelete.id,
            userId: currentUser.email
          } 
        })
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete query');
      }
      await fetchSavedQueries();
      setIsModalOpen(false);
      setQueryToDelete(null);
    } catch (error) {
      console.error('Error deleting saved query:', error);
      setError('An error occurred while deleting the query. Please try again.');
    } finally {
      setIsDeletingQuery(false);
    }
  };

  const renderQueryGrid = () => (
    <div className="mt-8">
      <h2 className="text-gray-500 mb-6 text-xl font-semibold">Saved Queries</h2>
      <div className="mb-4 flex justify-start space-x-4 text-sm">
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio text-secondary"
            name="filter"
            value="all"
            checked={filter === 'all'}
            onChange={() => setFilter('all')}
          />
          <span className="ml-2 text-gray-600">All Queries</span>
        </label>
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio text-secondary"
            name="filter"
            value="my"
            checked={filter === 'my'}
            onChange={() => setFilter('my')}
          />
          <span className="ml-2 text-gray-600">My Queries</span>
        </label>
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio text-secondary"
            name="filter"
            value="shared"
            checked={filter === 'shared'}
            onChange={() => setFilter('shared')}
          />
          <span className="ml-2 text-gray-600">Shared Only</span>
        </label>
      </div>
      {errorMessage && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <p>{errorMessage}</p>
        </div>
      )}
      {filteredQueries.length === 0 ? (
        <p className="text-center text-gray-500">No queries found.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredQueries.map(query => (
            <Card key={query.id} className="h-48 relative">
              <div className="flex flex-col h-full">
                <div className="flex justify-between items-start">
                  <h3 className="text-base font-semibold pr-2">{query.name}</h3>
                  <span className={`px-2 py-1 text-xs font-semibold rounded-full ${query.isPrivate ? 'bg-secondary bg-opacity-20 text-secondary' : 'bg-gray-200 text-gray-600'}`}>
                    {query.isPrivate ? 'Private' : 'Public'}
                  </span>
                </div>
                <div className="flex-grow overflow-hidden mt-1">
                  <p className="text-sm text-gray-600 line-clamp-2">{query.userQuery}</p>
                </div>
                <div className="mt-auto">
                  <p className="text-xs text-gray-400 mb-2">{query.userId}</p>
                  <div className="flex justify-between items-center">
                    <Button 
                      onClick={() => handleRunSavedQuery(query)}
                      icon={Play}
                      className="text-primary"
                    >
                      Run Query
                    </Button>
                    {query.userId === currentUser.email && (
                      <Button 
                        onClick={() => handleDeleteQuery(query)}
                        icon={Trash2}
                        className="text-primary"
                        aria-label="Delete query"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );

  if (isLoading) return <div className="text-center py-4">Loading saved queries...</div>;
  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;

  return (
    <div>
      {renderQueryGrid()}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setQueryToDelete(null);
        }}
        onSubmit={confirmDeleteQuery}
        title="Confirm Deletion"
        message={`Are you sure you want to delete the query "${queryToDelete?.name}"?`}
        isInput={false}
        confirmText="Delete"
        cancelText="Cancel"
        isLoading={isDeletingQuery}
      />
    </div>
  );
});

export default SavedQueriesComponent;