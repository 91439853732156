// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, functions, httpsCallable } from '../firebase';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged 
} from 'firebase/auth';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  async function signup(email, password) {
    console.log('Signup attempt for email:', email);
    const checkAllowedEmail = httpsCallable(functions, 'checkAllowedEmail');
    try {
      console.log('Calling checkAllowedEmail function');
      const result = await checkAllowedEmail({ email });
      console.log('checkAllowedEmail result:', result.data);
      
      if (result.data.allowed) {
        console.log('Email is allowed, creating user');
        return createUserWithEmailAndPassword(auth, email, password);
      } else if (result.data.error) {
        console.error('Error from checkAllowedEmail:', result.data.error);
        throw new Error(result.data.error);
      } else {
        console.log('Email is not allowed');
        throw new Error('Email not allowed to register');
      }
    } catch (error) {
      console.error('Error in signup process:', error);
      if (error.message === 'Email not allowed to register') {
        throw error;
      } else if (error.message.includes('Configuration error')) {
        throw new Error('There was a problem with the registration system. Please contact support.');
      } else if (error.message === 'Internal server error') {
        throw new Error('An unexpected error occurred. Please try again later.');
      } else {
        throw new Error('An error occurred during signup. Please try again.');
      }
    }
  }

  async function login(email, password) {
    try {
      console.log('Login attempt for email:', email);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful');
      return userCredential;
    } catch (error) {
      console.error('Error in login process:', error);
      throw new Error('Failed to log in. Please check your email and password.');
    }
  }

  async function logout() {
    try {
      console.log('Logout attempt');
      await signOut(auth);
      console.log('Logout successful');
    } catch (error) {
      console.error('Error in logout process:', error);
      throw new Error('Failed to log out. Please try again.');
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
      console.log('Auth state changed. Current user:', user ? user.email : 'None');
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}