// src/Routes.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import DynamicQuery from './pages/DynamicQuery';
import SignIn from './pages/SignIn';
import Signup from './pages/Signup';
import StaticDashboard from './pages/StaticDashboard';
import PrivateRoute from './components/PrivateRoute';
import { useAuth } from './contexts/AuthContext';

const AppRoutes = () => {
  const { currentUser } = useAuth();

  return (
    <Routes>
      <Route path="/signin" element={currentUser ? <Navigate to="/dynamic" /> : <SignIn />} />
      <Route path="/signup" element={currentUser ? <Navigate to="/dynamic" /> : <Signup />} />
      <Route path="/dynamic" element={<PrivateRoute><DynamicQuery /></PrivateRoute>} />
      <Route path="/StaticDashboard" element={<PrivateRoute><StaticDashboard /></PrivateRoute>} />
      <Route path="/" element={<Navigate to="/dynamic" />} />
      <Route path="*" element={<Navigate to="/dynamic" />} />
    </Routes>
  );
};

export default AppRoutes;