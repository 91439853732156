import React from 'react';
import { BarChart2, LineChart, PieChart } from 'lucide-react';
import Button from '../Button';

const ChartToggle = ({ chartType, onChange }) => {
  return (
    <>
      <Button
        onClick={() => onChange('bar')}
        icon={BarChart2}
        className={`text-sm h-9 px-3 py-2 ${chartType === 'bar' ? 'bg-blue-100' : ''}`}
      >
        Bar
      </Button>
      <Button
        onClick={() => onChange('line')}
        icon={LineChart}
        className={`text-sm h-9 px-3 py-2 ${chartType === 'line' ? 'bg-blue-100' : ''}`}
      >
        Line
      </Button>
      <Button
        onClick={() => onChange('pie')}
        icon={PieChart}
        className={`text-sm h-9 px-3 py-2 ${chartType === 'pie' ? 'bg-blue-100' : ''}`}
      >
        Pie
      </Button>
    </>
  );
};

export default ChartToggle;