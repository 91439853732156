import React from 'react';
import Card from '../components/Card';
import LookerStudioVis from '../components/LookerStudioVis';

const dashboardItems = [
  { id: 1, title: 'Matchers Over Time', embedUrl: 'https://lookerstudio.google.com/embed/reporting/f7d1271d-2f04-427d-ad09-1ce020bb3a70/page/urB8D', width: 'full', height: 400 },
  { id: 2, title: 'Matchers Gender', embedUrl: 'https://lookerstudio.google.com/embed/reporting/b8997009-0973-4c3e-b4b7-6df39183d78b/page/tEnnC', width: 'one-third', height: 250 },
  { id: 3, title: 'Matchers Received Proposals', embedUrl: 'https://lookerstudio.google.com/embed/reporting/33e566b7-4f77-4e9b-b5dd-34f052cd1453/page/tEnnC', width: 'one-third', height: 250 },
  // Add more dashboard items as needed
];

const getGridClass = (width) => {
  switch (width) {
    case 'full':
      return 'col-span-12';
    case 'half':
      return 'col-span-12 md:col-span-6';
    case 'one-third':
      return 'col-span-12 md:col-span-4';
    default:
      return 'col-span-12';
  }
};

const StaticDashboard = () => {
  return (
    <div className="container mx-auto px-4 py-4 sm:py-8">
      <h1 className="text-gray-500 mb-6 text-xl font-semibold">Static Dashboard</h1>
      <div className="grid grid-cols-12 gap-6">
        {dashboardItems.map((item) => (
          <div key={item.id} className={getGridClass(item.width)}>
            <Card title={item.title} titleSize="text-card-title-sm">
              <div style={{ height: `${item.height}px` }}>
                <LookerStudioVis embedUrl={item.embedUrl} title={item.title} />
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StaticDashboard;