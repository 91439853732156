import React, { useState, useRef, useEffect } from 'react';
import { SendHorizontal } from 'lucide-react';

const QueryInput = ({ onSubmit, isLoading }) => {
  const [inputQuery, setInputQuery] = useState('');
  const textareaRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputQuery.trim()) {
      onSubmit(inputQuery);
    }
  };

  const handleTextareaChange = (e) => {
    setInputQuery(e.target.value);
    adjustTextareaHeight();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const newHeight = Math.min(textarea.scrollHeight, 3 * 24); // 24px is approx. one line height
      textarea.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputQuery]);

  return (
    <form onSubmit={handleSubmit} className="mb-4">
      <div className="relative">
        <img
          src="/favicon.ico"
          alt="Company Icon"
          className="absolute left-2.5 top-3 w-6 h-6"
        />
        <textarea
          ref={textareaRef}
          value={inputQuery}
          onChange={handleTextareaChange}
          onKeyDown={handleKeyDown}
          placeholder="Ask me about your data..."
          className="w-full pl-10 pr-12 py-3 bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800 text-base placeholder-gray-400 min-h-[48px] max-h-[72px] resize-none overflow-y-auto"
          disabled={isLoading}
          rows={1}
          style={{
            lineHeight: '24px',
            transition: 'height 0.1s ease-out'
          }}
        />
        <button
          type="submit"
          className={`absolute right-2 top-3 p-1 rounded-full transition-colors duration-200 ${
            inputQuery.trim() ? 'text-secondary hover:bg-secondary hover:bg-opacity-10' : 'text-text-lighter'
          }`}
          disabled={isLoading || !inputQuery.trim()}
        >
          <SendHorizontal size={20} />
        </button>
      </div>
      {isLoading && (
        <div className="flex justify-center items-center mt-4">
          <div className="bubbling-loader">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <span className="ml-2 text-[#4B5563] text-base">Processing query...</span>
        </div>
      )}
    </form>
  );
};

export default QueryInput;